<template scoped>
  <div>
    
  </div>
</template>
      
<script scoped>
export default {
  props: {
    logic: Object,
  },
  methods: {
    
  },
  data: function () {
    return {
      
    };
  },
};
</script>    